<template>
    <div>
        <div class="filter-panel">
            <CSSelect style="margin-right: 20px">
                <select
                    v-model="timeSelect"
                    style="color: #999;width:118px;height:30px"
                    :style="{
                        color: timeSelect != '' ? '#000' : '#999',
                    }"
                >
                    <option value="0" >自定义</option>
                    <option
                        v-for="(state, key) in timeSelectList"
                        :key="key"
                        :value="key"
                    >
                        {{ state }}
                    </option>
                </select>
            </CSSelect>
            <CSSelect style="margin-right: 0px">
                <el-date-picker
                    :disabled="timeSelectEnable"
                    v-model="startDate"
                    type="date"
                    :picker-options="{disabledDate: elDisabledDate}"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择开始时间"
                    style="width: 140px;height:30px"
                    prefix-icon="el-icon-time"
                    :editable="false"
                ></el-date-picker>
            </CSSelect>
            <span class="inline-gray"></span
            >
            <CSSelect style="margin-right: 30px">
                <el-date-picker
                    :disabled="timeSelectEnable"
                    v-model="endDate"
                    type="date"
                    :picker-options="{disabledDate: elDisabledDate}"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择结束时间"
                    style="width: 140px;height:30px"
                    prefix-icon="el-icon-time"
                    :editable="false"
                ></el-date-picker>
            </CSSelect>
            <button class="btn btn-primary" @click="getData()">查询</button>
        </div>

        <!-- 用户图表 -->
        <div class="result-panel" :style="{height:viewPortHeight - 80 + 'px', 'overflow-y': 'auto'}">
          <div id="userChart" class="chart-view">

          </div>
        </div>

        <!-- 公共服务 -->
        <div class="result-panel" style="padding-top:30px;padding-left:30px">
            <div class="col-lg-12" style="padding-left:0px">
                <button
                    type="button"
                    v-for="(item, n) in publicServiceTabBar"
                    :key="`${item.state}_${n}`"
                    style="width: 150px; margin-right: 0px"
                    :class="[
                        'btn',
                        'btn-lg',
                        'btn-secondary',
                        item.state == publicServiceTabBarState.state ? 'btn-primary' : '',
                    ]"
                    @click="changePublicServiceStateTabBar(item.state)"
                >
                    <!-- <el-badge is-dot class="item" ></el-badge> -->
                    {{ item.name }}
                </button>
            </div>

            <div class="result-panel">
                <div class="col-lg-12" style="padding-left:0px">
                    <button
                        type="button"
                        v-for="(item, n) in publicServiceUseTabBar"
                        :key="`${item.state}_${n}`"
                        style="width: 150px; margin-right: 0px;padding-right:0px;padding-left:0px"
                        :class="[
                            'btn',
                            'btn-lg',
                            'btn-secondary',
                            item.state == publicServiceUseTabBarState.state ? 'btn-primary' : '',
                        ]"
                        @click="changePublicSonStateTabBar(item)"
                    >
                        <!-- <el-badge is-dot class="item" ></el-badge> -->
                        {{ item.name }}
                    </button>
                </div>
            </div>
            <div :style="{height:viewPortHeight - 149 + 'px', 'overflow-y': 'auto'}">
              <div class="chart-view" id="publicServiceChart">

              </div>
            </div>

        </div>

        <!-- 社区相关 -->
        <div class="result-panel" style="padding-top:30px;padding-left:30px">
            <div class="col-lg-12" style="padding-left:0px">
                <button
                    type="button"
                    v-for="(item, n) in socialServiceTabBar"
                    :key="`${item.state}_${n}`"
                    style="width: 230px; margin-right: 0px;padding-right:0px;padding-left:0px"
                    :class="[
                        'btn',
                        'btn-lg',
                        'btn-secondary',
                        item.state == socialServiceTabBarState.state ? 'btn-primary' : '',
                    ]"
                    @click="changeSocialServiceTabBar(item)"
                >
                    <!-- <el-badge is-dot class="item" ></el-badge> -->
                    {{ item.name }}
                </button>
            </div>

            <div :style="{height:viewPortHeight - 80 + 'px', 'overflow-y': 'auto'}" >
              <div class="chart-view" id="socialServiceChart">

              </div>
            </div>
        </div>

        <!-- 果树积分相关 -->
        <div class="result-panel" style="padding-top:30px;padding-left:30px">
            <div class="col-lg-12" style="padding-left:0px">
                <button
                    type="button"
                    v-for="(item, n) in pointsTabBar"
                    :key="`${item.state}_${n}`"
                    style="width: 350px; margin-right: 0px;padding-right:0px;padding-left:0px"
                    :class="[
                        'btn',
                        'btn-lg',
                        'btn-secondary',
                        item.state == pointsTabBarState.state ? 'btn-primary' : '',
                    ]"
                    @click="changePointsTabBar(item)"
                >
                    <!-- <el-badge is-dot class="item" ></el-badge> -->
                    {{ item.name }}
                </button>
            </div>

            <div :style="{height:viewPortHeight - 80 + 'px', 'overflow-y': 'auto'}"><div class="chart-view" id="pointsChart"></div></div>
        </div>

        <!-- 问卷 -->
        <div class="result-panel data">
            <span>问卷发放数：{{surveyData.sendCount}}</span>
            <span style="margin-left:99px;margin-right:67px">提交数：{{surveyData.submitCount}}</span>
            <span class="allow-click" @click="queryOperationalItemDetail(OPERATIONAL_ITEM_TYPE.QUESTIONNAIRE_DATA)">查看明细</span>
        </div>

        <!-- 投诉 -->
        <div class="result-panel data">
            <span>投诉数：{{complaintData.reportNum}}</span>
            <span style="margin-left:127px">已处理数：{{complaintData.handleNum}}</span>
            <span style="margin-left:83px">评论数：{{complaintData.evaluationNum}}</span>
            <span style="margin-left:97px">平均星级：{{complaintData.avgScore}}</span>
            <span style="margin-left:69px" class="allow-click" @click="queryOperationalItemDetail(OPERATIONAL_ITEM_TYPE.COMPLAINT_DATA)">查看明细</span>
        </div>

        <!-- 活动 -->
        <div class="result-panel data" style="height:120px">
            <div class="col-lg-12" style="padding-left:0px;margin-bottom:20px">
                <button
                    type="button"
                    v-for="(item, n) in activityTabBar"
                    :key="`${item.state}_${n}`"
                    style="width: 350px; margin-right: 0px;padding-right:0px;padding-left:0px"
                    :class="[
                        'btn',
                        'btn-lg',
                        'btn-secondary',
                        item.state == activityTabBarState.state ? 'btn-primary' : '',
                    ]"
                    @click="changeActivityTabBar(item)"
                >
                    <!-- <el-badge is-dot class="item" ></el-badge> -->
                    {{ item.name }}
                </button>
            </div>
            <div v-if="activityTabBarState.state == 1">
                <span>活动发布数：{{activityData.releaseCount}}</span>
                <span style="margin-left:99px;margin-right:53px">访问人数：{{activityData.visitCount}}</span>
                <span class="allow-click" @click="queryOperationalItemDetail(OPERATIONAL_ITEM_TYPE.ACTIVE_DATA)">查看明细</span>
            </div>
            <div v-if="activityTabBarState.state == 2">
                <span>活动发布数：{{activityData.releaseCount}}</span>
                <span style="margin-left:99px">访问人数：{{activityData.visitCount}}</span>
                <span style="margin-left:83px">报名人数：{{activityData.enrollCount}}</span>
                <span style="margin-left:97px">签到人数：{{activityData.signCount}}</span>
                <span style="margin-left:69px" class="allow-click" @click="queryOperationalItemDetail(OPERATIONAL_ITEM_TYPE.ACTIVE_DATA)">查看明细</span>
            </div>
        </div>
    </div>
</template>
<script>
import CSSelect from "@/components/common/CSSelect";
import dayjs from 'dayjs';
import {
    activityDateUrl,
    queryTicketPropertyDataUrl,
    surveyDataUrl,
    adminQueryUrl,
    regionAvtiveUserDataUrl,
    regionBizDataUrl,
    complaintDataUrl,
    getadminQueryUrl,
    querySingleSurveyUrl,
} from '@/requestUrl';
import {STORAGE_KEY} from "@/constant";
import {OPERATIONAL_ITEM_TYPE} from "@/views/Statistics/operationalItemType";

export default {
    name: "operationalData",
    components:{
        CSSelect,
    },
    data(){
        return{
            OPERATIONAL_ITEM_TYPE,
            viewPortHeight: 500,
            activitiContentData:{},
            activityPreviewVisible:false,
            previewData:{},//问卷内容所需数据
            surveyContentEnable:false,//控制问卷内容显示
            complaintEnable:false,
            surveyEnable:false,
            timeSelect:"1",//所选查询日期
            timeSelectEnable:true,//日期是否可选
            timeSelectList:{
                1:"近7天",
                2:"近14天",
                3:"近30天",
            },
            startDate:dayjs().subtract(7,"day").format("YYYY-MM-DD"),
            endDate:dayjs().subtract(1, 'day').format("YYYY-MM-DD"),
            publicServiceTabBar: [
                { state: 1, name: "闪购" },
                { state: 2, name: "企业服务" },
                { state: 3, name: "上门服务" },
                { state: 4, name: "会议室" },
            ],
            publicServiceTabBarState: {
                state: 1
            },
            mallTabBar:[//闪购标签栏
                {state: 1, name: "访问人数/购买人数",categories:[1,1000]},
                {state: 2, name: "支付金额",categories:[1001]},
                {state: 3, name: "评价数",categories:[1002,1003]},
            ],
            enterpriseTabBar: [//企业服务标签栏
                { state: 1, name: "访问人数/签约人数",categories:[2,2000]},
                { state: 2, name: "支付金额",categories:[2001]},
                { state: 3, name: "评价数",categories:[2002,2003]},
            ],
            visitingTabBar: [//上门服务标签栏
                {state: 1, name: "访问人数/购买人数",categories:[3,3000]},
                {state: 2, name: "支付金额",categories:[3001]},
                {state: 3, name: "评价数",categories:[3002,3003]},
            ],
            meetingRoomTabBar: [//会议室标签栏
                { state: 1, name: "访问人数/使用人数",categories:[4,4000]},
                { state: 2, name: "共享会议室支付金额",categories:[4002]},
                { state: 3, name: "共享会议室评价数",categories:[4003,4004]},
            ],
            publicServiceUseTabBar: [//当前使用的二级tabbar
                {state: 1, name: "访问人数/购买人数",categories:[1,1000]},
                {state: 2, name: "支付金额",categories:[1001]},
                {state: 3, name: "评价数",categories:[1002,1003]},
            ],
            publicServiceUseTabBarState:{
                state: 1,
                categories:[1,1000]
            },
            socialServiceTabBar: [
                { state: 1, name: "社区访问人数/发帖人数/回复人数",categories:[5,12,11]},
                { state: 2, name: "发帖数/回复数",categories:[5000,5001]},
            ],
            socialServiceTabBarState:{
                state:1,
                categories:[5,12,11]
            },
            pointsTabBar: [
                { state: 1, name: "果树访问人数/签到人数/摘取苹果人数/兑换现金人数",categories:[6,7,10,9]},
                { state: 2, name: "产生苹果数",categories:[6000,6001,6002,6003]},
                { state: 3, name: "兑换金额",categories:[6004]},
            ],
            pointsTabBarState:{
                state:1,
                categories:[6,7,10,9]
            },
            activityTabBar:[
                { state: 1,name: "无需报名活动",isEnroll:0},
                { state: 2,name: "需要报名活动",isEnroll:1},
            ],
            activityTabBarState:{
                state:1
            },
            complaintData: {}, //投诉数据
            activityData: {}, //活动数据
            surveyData: {},//问卷数据
            surveyDetailData:{},//问卷详情数据
            complaintDetailData:[],//投诉详情数据
            activityDetailData:[],//活动详情数据
            /*  为了方便知道数值对应的意思，请勿删除
              categories:{
                1:"闪购访问人数",
                2:"企业服务访问人数",
                3:"上门服务访问人数",
                4:"共享会议室访问人数",
                5:"社区访问人数",
                6:"果树访问人数",
                7:"果树签到人数",
                9:"兑换现金人数",
                10:"摘取苹果人数",
                12:"社区发帖人数",
                11:"社区回复人数",
                1000:"闪购购买人数",
                2000:"企业服务签约人数",
                3000:"上门服务购买人数",
                4000:"会议室使用人数",
                1001:"闪购支付金额(单位分,下同)",
                2001:"企业服务支付金额",
                3001:"上门服务支付金额",
                4001:"会议室支付金额",
                1002:"闪购评价数",
                2002:"企业服务评价数",
                3002:"上门服务评价数",
                4003:"会议室评价数",
                1003:"闪购平均星级",
                2003:"企业服务平均星级",
                3003:"上门服务平均星级",
                4004:"会议室平均星级",
                5000:"社区发帖数",
                5001:"社区回复数",
                6000:"苹果产生数",
                6001:"苹果摘取数",
                6002:"苹果兑换数",
                6003:"苹果掉落数",
                6004:"苹果兑换金额"
            },*/
        }
    },
    watch:{
        timeSelect: function(val){
            if(val == 0){
                this.timeSelectEnable = false;
            }else{
                this.timeSelectEnable = true;
                let day = 7;
                switch(val){
                    case "2":
                        day = 14;
                        break;
                    case "3":
                        day = 30;
                        break;
                }
                this.startDate = dayjs().subtract(day,'day').format("YYYY-MM-DD");
                this.endDate = dayjs().subtract(1, 'day').format("YYYY-MM-DD");
            }
        }
    },
    created() {
        sessionStorage.removeItem(STORAGE_KEY.OPERATIONAL_NEXT_PAGE_DATA);
        window.addEventListener("keydown",this.getDataDown);
    },
    beforeDestroy(){
        window.removeEventListener("keydown",this.getDataDown);
    },
    deactivated(){
        window.removeEventListener("keydown",this.getDataDown);
    },
    mounted(){
        const queryParams = JSON.parse(sessionStorage.getItem(STORAGE_KEY.OPERATIONAL_QUERY_PARAMS));
        if (this.$route.path && queryParams) {
          this.endDate = queryParams.endDate;
          this.startDate = queryParams.startDate;
          this.timeSelect = queryParams.timeSelect;
        }

        console.log('加载完成')
        this.viewPortHeight = document.querySelector('.page-content-view').clientHeight - 30;
        this.getData();
        window.onresize = () => {
          if (this.$route.name === 'operationalData') {
            this.viewPortHeight = document.querySelector('.page-content-view').clientHeight - 30;
            this.getData();
          }
        }
    },
    methods:{
        getDataDown(e){
            if(e.keyCode == 13){
                this.getData();
            }
        },
        elDisabledDate(date) {
          return dayjs(date).isAfter(dayjs().subtract(1, 'day'));
        },
        getData(){
            this.getActivityUserData();
            this.getTicketData(2);
            this.getActivityData(0);
            this.getSurveyData();
            this.getBizData(this.pointsTabBarState.categories,"pointsChart");
            this.getBizData(this.socialServiceTabBarState.categories,"socialServiceChart");
            this.getBizData(this.publicServiceUseTabBarState.categories,"publicServiceChart");
        },
        changePublicServiceStateTabBar(state) {
            if (this.publicServiceTabBarState.state != state) {
                this.publicServiceUseTabBarState.state = 1;
                this.publicServiceTabBarState.state = state;
                const tabBar={
                    1:this.mallTabBar,
                    2:this.enterpriseTabBar,
                    3:this.visitingTabBar,
                    4:this.meetingRoomTabBar
                }
                this.publicServiceUseTabBar=tabBar[state];
                this.getBizData(this.publicServiceUseTabBar[0].categories,"publicServiceChart");
            }
            this.hidden = false;
        },
        changePublicSonStateTabBar(item){
            if(this.publicServiceUseTabBarState.state != item.state){
                this.publicServiceUseTabBarState.state = item.state;
                this.publicServiceUseTabBarState.categories=item.categories;
                this.getBizData(item.categories,"publicServiceChart");
            }
            this.hidden = false;
        },
        changeSocialServiceTabBar(item){
            if(this.socialServiceTabBarState.state != item.state){
                this.socialServiceTabBarState.state = item.state;
                this.socialServiceTabBarState.categories=item.categories;
                this.getBizData(item.categories,"socialServiceChart");
            }
            this.hidden = false;
        },
        changePointsTabBar(item){
            if(this.pointsTabBarState.state != item.state){
                this.pointsTabBarState.state = item.state;
                this.pointsTabBarState.categories=item.categories;
                this.getBizData(item.categories,"pointsChart");
            }
            this.hidden = false;
        },
        changeActivityTabBar(item){
            if(this.activityTabBarState.state != item.state){
                this.activityTabBarState.state = item.state;
                this.getActivityData(item.isEnroll);
            }
            this.hidden = false;
        },
        buildChart(chartData,id,orderKeys){
            const series = [],
                  title = [],
                  convertCategories = ["支付金额","平均星级", "兑换金额"];
            console.log(orderKeys);
            var keys=orderKeys!=undefined?orderKeys:Object.keys(chartData.data);
            for(let key in keys){
                let seriesData=chartData.data[keys[key]];
                convertCategories.forEach(element => {
                    if(keys[key] === element){
                        seriesData =  seriesData.map(item => item/100);
                    }
                });

                series.push({
                    name: keys[key],
                    type: 'line',
                    smooth: false,//控制是否是曲线
                    data: seriesData
                });
                title.push(keys[key]);
            }

            this.$echarts.init(document.getElementById(id)).dispose();
            const echarts=this.$echarts.init(document.getElementById(id));
            const option = {
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: title,
                    top:20
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: chartData.title
                },
                yAxis: {
                    type: 'value'
                },
                series: series
            };

            echarts.setOption(option);
        },
        //获取活跃用户数据
        getActivityUserData(){
            const startDate=dayjs(this.startDate).format("YYYYMMDD");
            const endDate=dayjs(this.endDate).format("YYYYMMDD");
            this.$fly.post(regionAvtiveUserDataUrl,{
                regionCode:this.$vc.getCurrentRegion().code,
                startDate,
                endDate
            })
            .then((res) => {
                if(res.code != 0){
                    return;
                }
                this.buildChart(res.data,"userChart",["新增访问人数","次日新增访问留存人数","访问人数","次日访问留存人数"]);
            });
        },
        //获取业务数据
        getBizData(categories,id){
            const startDate=dayjs(this.startDate).format("YYYYMMDD");
            const endDate=dayjs(this.endDate).format("YYYYMMDD");
            this.$fly.post(regionBizDataUrl,{
                regionCode:this.$vc.getCurrentRegion().code,
                startDate,
                endDate,
                categories
            })
            .then((res) => {
                if(res.code != 0){
                    return;
                }
                this.buildChart(res.data,id);
            });
        },
        //获取报事/投诉数据 type 1.报事2.投诉
        getTicketData(type) {
            const startDate=this.startDate;
            const endDate=this.endDate;
            this.$fly
                .post(queryTicketPropertyDataUrl, {
                    startDate,
                    endDate,
                    regionCode: this.$vc.getCurrentRegion().code,
                    type,
                })
                .then((res) => {
                    if (res.code != 0) {
                        return;
                    }
                    this.complaintData = res.data;
                });
        },
        //获取活动数据  1.需报名活动0.无需报名活动
        getActivityData(isEnroll){
            const {
                startDate,
                endDate
            } = this;
            this.$fly.post(activityDateUrl,{
                startTime:startDate,
                endTime:endDate,
                regionCode:this.$vc.getCurrentRegion().code,
                isEnroll:isEnroll
            })
            .then((res) => {
                if(res.code != 0){
                    return;
                }
                this.activityData=res.data;
            });
        },
        //获取问卷数据
        getSurveyData(){
            const {startDate,endDate} = this;
            this.$fly.post(surveyDataUrl,{
                regionCode:this.$vc.getCurrentRegion().code,
                startDate,
                endDate
            }).then((res) => {
                if(res.code != 0){
                    return;
                }
                this.surveyData=res.data;
            })
        },
        /**
         * 查看运营某项数据项的详情
         * @param {Number} itemType 数据项类型
         * */
        queryOperationalItemDetail(itemType) {
            const startDate = this.startDate + " 00:01",
                 endDate = this.endDate + " 23:59",
                regionCode = this.$vc.getCurrentRegion().code;
            const routerParams = {
                [OPERATIONAL_ITEM_TYPE.QUESTIONNAIRE_DATA]: {
                   query: {
                       startDate,
                       endDate,
                       regionCode,
                       containDelete: 0,
                   },
                    itemData: this.surveyData
                },
                [OPERATIONAL_ITEM_TYPE.COMPLAINT_DATA]: {
                    query: {
                        regionId: regionCode,
                        startTime: startDate,
                        endTime: endDate
                    },
                    itemData: this.complaintData,
                },
                [OPERATIONAL_ITEM_TYPE.ACTIVE_DATA]: {
                    query: {
                        regionCode,
                        startDate,
                        endDate,
                        //为1查询需报名活动 为0查无需报名活动
                        isEnroll: this.activityTabBarState.state === 1 ? 0 : 1,
                        containDelete: 2
                    },
                    itemData: this.activityData,
                },
            };
            sessionStorage.setItem(STORAGE_KEY.OPERATIONAL_NEXT_PAGE_DATA, JSON.stringify(routerParams[itemType].itemData));
            this.$router.push({
                name: 'operationalItemDataDetail',
                query: {
                    ...routerParams[itemType].query,
                },
                params: {
                    type: itemType,
                }
            })
        },
        //获取问卷内容
        getSurveyContentData(id){
            this.$fly.post(querySingleSurveyUrl,{
                regionCode:this.$vc.getCurrentRegion().code,
                id
            })
            .then((res) => {
                if(res.code != 0){
                    return;
                }
                res.data.questions = res.data.questions.map(question => {
                  if (question.type !== 3) {
                    question.options = JSON.parse(question.options);
                  }
                  return question;
                })
                this.previewData = res.data;
                this.surveyContentEnable=true;
            });
        },

        complaintEvaluationAlter(item){
            this.$CSDialog.alert({
                center:true,
                title:"查看评价",
                messageHtml: `
                    <table CellPadding="15px" style="text-align:left">
                        <tr>
                            <td>评价时间</td><td>${item.evaluateTime}</td>
                        </tr>
                        <tr>
                            <td>评价星级</td><td>${item.score}</td>
                        </tr>
                        <tr>
                            <td>评价内容</td><td>${item.evaluateContent}</td>
                        </tr>
                    </table>
                `
            })
        },
        // 查看数据统计
        lookStatistics(id) {
          const {timeSelect, startDate, endDate} = this;
            sessionStorage.setItem(STORAGE_KEY.OPERATIONAL_QUERY_PARAMS, JSON.stringify({
              timeSelect,
              startDate,
              endDate,
            }))
            this.$router.push({ path: "questionnaire/log", query: { id } });
        },
    }
}
</script>
<style>
    .chart-view {
      width: 100%;
      height:100%;
      min-width: 1000px;
    }
    .data{
        font-size: 14px;
        color: black;
        padding-top:20px;
        padding-bottom:20px;
        padding-left:30px
    }
    .aExistStyle,a{
        color: #1DAFFF;
        text-decoration: underline;
    }
    .col-lg-12 .btn{
        border-radius: 0;
    }
    .col-lg-12 .btn:first-child{
        border-radius: 3px 0px 0px 3px;
    }
    .col-lg-12 .btn:last-child{
        border-radius: 0px 3px 3px 0px;
    }
    .aNotStyle,.aNotStyle:hover{
        color: black;
        text-decoration: none;
        cursor:default;
    }
</style>
